import { FunctionComponent } from "react";
import Cards from "./Cards";

const FrameComponent2: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0 box-border max-w-full text-center text-45xl text-font-color-dark-800 font-palanquin ">
      <div id="solutions" className="flex-1 flex flex-col items-center justify-start py-16 px-20 mq1200:py-6 mq1200:px-8 mq450:px-4 mq450:py-6 box-border gap-[64px] mq1200:gap-[32px] max-w-full">
        <div className="w-[349px] flex flex-col items-center justify-start gap-[24px] max-w-full">
          <h1 className="m-0 self-stretch relative text-inherit tracking-[-0.04em] leading-[100%] font-medium font-inherit mq450:text-19xl mq450:leading-[38px] mq800:text-32xl mq800:leading-[51px]">
            Our Solutions
          </h1>
          <div className="relative text-5xl leading-[29px] text-darkgray mq450:text-lgi mq450:leading-[23px]">
            "Innovate. Integrate. Empower."
          </div>
        </div>
        <div className="self-stretch flex flex-col items-center justify-start text-left text-5xl mq800:gap-[16px]">
          <div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[32px] mq450:gap-[16px]">
            <Cards
              iconTablerIconsHierarchy="/icon--tablericons--hierarchy.svg"
              massOnChainPayments="Mass On-Chain Payments"
              streamlineBulkTransaction="Streamline bulk transactions globally in real-time."
            />
            <Cards
              iconTablerIconsHierarchy="/icon--tablericons--arrowsdoubleswne.svg"
              massOnChainPayments={`FIAT & Crypto Swaps`}
              streamlineBulkTransaction="Fluid currency exchanges enhancing your financial fluidity.
"
            />
            <Cards
              iconTablerIconsHierarchy="/icon--tablericons--stack3.svg"
              massOnChainPayments="Recurring Payroll Processing"
              streamlineBulkTransaction="Automate and secure your payment schedules."
            />
            <Cards
              iconTablerIconsHierarchy="/safety.svg"
              massOnChainPayments="Escrow Services"
              streamlineBulkTransaction="Trust our transparent and efficient escrow transactions."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent2;
