import { FunctionComponent } from "react";

const PartnerWithUs: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-center justify-start py-12 px-20 mq1200:py-6 mq1200:px-8 mq450:p-4 box-border max-w-full z-[9] text-center text-5xl text-white font-palanquin mq450:px-4 mq450:py-6 mq450:box-border mq800:gap-[17px] mq800:py-[31px] mq800:px-10 mq800:box-border">
      <div className="self-stretch rounded-13xl mq450:rounded-3xl [background:linear-gradient(180deg,_#2e79ee,_#30a7f9)] overflow-hidden flex flex-col items-center justify-start relative py-12 mq450:py-8 gap-[48px]  max-w-full mq800:gap-[24px]">
        <div className="w-[411px] flex flex-col items-center justify-start gap-[24px] max-w-full text-45xl">
          <h1 className="m-0 self-stretch relative text-inherit tracking-[-0.04em] leading-[100%] font-medium font-inherit mq450:text-19xl mq450:leading-[38px] mq800:text-32xl mq800:leading-[51px]">
            Partner With Us
          </h1>
          <div className="w-[338px] h-[29px] relative text-5xl leading-[120%] hidden max-w-full mq450:text-lgi mq450:leading-[23px]">
            "Collaborate with Pays Solutions"
          </div>
        </div>
        <div className="relative leading-[29px] inline-block max-w-full mq450:text-lgi mq450:leading-[23px]">
          We invite entities across Fintech and tech ecosystem to join us:
        </div>
        <div className="self-stretch flex flex-row mq450:flex-col items-start justify-center py-0 px-[289px] box-border gap-[32px] max-w-full text-xl mq450:pl-5 mq450:pr-5 mq450:box-border mq800:gap-[16px] mq800:pl-[72px] mq800:pr-[72px] mq800:box-border mq1325:flex-wrap mq1325:pl-36 mq1325:pr-36 mq1325:box-border">
          <div className="flex flex-col items-start justify-start gap-[32px] min-w-[329px] max-w-full mq450:gap-[16px] mq1325:flex-1">
            <div className="flex flex-row items-start justify-start gap-[12px] text-left">
              <img
                className="h-6 w-6 relative rounded-3xl"
                loading="lazy"
                alt=""
                src="/frame-1000006927.svg"
              />
              <div className="relative leading-[100%] mq450:text-base mq450:leading-[16px]">
                <p className="m-0">{`Cryptocurrency Exchanges (CEXs) `}</p>
                <p className="m-0">and Decentralized Platforms</p>
              </div>
            </div>
            <div className="flex flex-row items-start justify-start gap-[12px]">
              <img
                className="h-6 w-6 relative rounded-3xl"
                alt=""
                src="/frame-1000006927.svg"
              />
              <div className="relative leading-[100%] mq450:text-base mq450:leading-[16px]">
                Venture Capitalists (VCs)
              </div>
            </div>
            <div className="flex flex-row items-start justify-start gap-[12px]">
              <img
                className="h-6 w-6 relative rounded-3xl"
                alt=""
                src="/frame-1000006927.svg"
              />
              <div className="relative leading-[100%] mq450:text-base mq450:leading-[16px]">
                Cloud Technology Partners
              </div>
            </div>
            <div className="flex flex-row items-start justify-start gap-[12px]">
              <img
                className="h-6 w-6 relative rounded-3xl"
                alt=""
                src="/frame-1000006927.svg"
              />
              <div className="relative leading-[100%] mq450:text-base mq450:leading-[16px]">
                Blockchain Integrators
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[32px] min-w-[222px] max-w-full mq450:gap-[16px]">
            <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
              <img
                className="h-6 w-6 relative rounded-3xl"
                alt=""
                src="/frame-1000006927.svg"
              />
              <div className="relative leading-[100%] mq450:text-base mq450:leading-[16px]">
                Crypto-focused Marketing Agencies
              </div>
            </div>
            <div className="flex flex-row items-start justify-start gap-[12px] max-w-full">
              <img
                className="h-6 w-6 relative rounded-3xl"
                alt=""
                src="/frame-1000006927.svg"
              />
              <div className="relative leading-[100%] mq450:text-base mq450:leading-[16px]">
                On/Off Ramp Solutions Providers
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
              <img
                className="h-6 w-6 relative rounded-3xl"
                alt=""
                src="/frame-1000006927.svg"
              />
              <div className="relative leading-[100%] mq450:text-base mq450:leading-[16px]">
                Key Opinion Leader (KOL) Agencies
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-center justify-start py-0 px-5 box-border gap-[24px] max-w-full">
          <div className="w-[880px] relative leading-[120%] inline-block max-w-full mq450:text-lgi mq450:leading-[23px]">
            Together, we can enhance the efficiency of cross-border payments and
            foster financial inclusion to support economic growth in underserved
            communities.
          </div>
          <button onClick={() => window.location.href='https://share-eu1.hsforms.com/1T3uP9uTCSK2S_z_EcE4Vqw2dwiun'} className="cursor-pointer [border:none] py-2.5 px-8 bg-white rounded-29xl flex flex-row items-center justify-center whitespace-nowrap hover:bg-gainsboro-100">
            <div className="relative text-xl tracking-[-0.01em] font-medium font-palanquin text-dodgerblue-200 text-left">
              Become a Partner
            </div>
          </button>
        </div>
        <img
          className="hidden w-[492px] h-[492px] absolute !m-[0] top-[-120px] left-[-149px] z-[1]"
          alt=""
          src="/empty-container.svg"
        />
        <div className="w-[515px] h-[423px] absolute !m-[0] top-[507px] left-[977px] z-[1] flex items-center justify-center">
          <img
            className="hidden w-full h-full z-[1] object-contain absolute left-[0px] top-[-6px] [transform:scale(1.031)]"
            loading="lazy"
            alt=""
            src="/frame-1000006937.svg"
          />
        </div>
      </div>
    </section>
  );
};

export default PartnerWithUs;
