import { FunctionComponent } from "react";

const Footer: FunctionComponent = () => {
  return (
    <footer className="self-stretch bg-white flex flex-col items-center justify-start p-20 mq1200:py-6 mq1200:px-8 mq450:px-4 mq450:py-6 box-border gap-[24px] max-w-full z-[11] text-left text-5xl text-gray-300 font-palanquin mq800:pl-10 mq800:pr-10 mq800:box-border">
      <div className="self-stretch flex flex-row mq450:flex-col items-start justify-between max-w-full gap-[20px] mq1125:flex-wrap">
        <div className="w-[408px] flex flex-col items-start justify-start gap-[16px] max-w-full">
          <img
            className="w-[233.1px] h-10 relative overflow-hidden shrink-0 object-contain"
            alt=""
            src="/pays-solution--logo-poziom-1-1@2x.png"
          />
          <div className="self-stretch h-6 relative tracking-[-0.04em] leading-[100%] flex items-center mq450:text-lgi mq450:leading-[19px]">
            <span>
              <span>{`Pay `}</span>
              <span className="text-royalblue">
                <span className="font-medium">anyone</span>
                <span>,</span>
              </span>
              <span>
                <span>{` `}</span>
              </span>
              <span className="text-royalblue">
                <span className="font-medium">anywhere</span>
                <span>,</span>
              </span>
              <span>
                <span>{` `}</span>
              </span>
              <span className="text-royalblue">
                <span className="font-medium">anytime</span>
              </span>
              <span>
                <span className="text-royalblue">{`, `}</span>
                <span>in seconds</span>
              </span>
            </span>
          </div>
        </div>
        <div className="hidden w-[375px] flex flex-col items-start justify-start gap-[16px] max-w-full text-base text-gray-200">
          <div className="relative tracking-[-0.01em] capitalize font-medium">
            newsletter Subscribe
          </div>
          <div className="self-stretch flex flex-row items-end justify-start gap-[12px] mq450:flex-wrap">
            <div className="flex-1 rounded-29xl box-border flex flex-row items-center justify-start py-[7px] px-5 min-w-[112px] border-[2px] border-solid border-whitesmoke-200">
              <input
                className="w-28 [border:none] [outline:none] font-medium font-palanquin text-base bg-[transparent] h-[29px] relative tracking-[-0.01em] text-darkgray text-left inline-block p-0"
                placeholder="Enter your email"
                type="text"
              />
            </div>
            <button className="cursor-pointer [border:none] py-[9.5px] px-5 bg-[transparent] rounded-29xl [background:linear-gradient(180deg,_#2e79ee,_#30a7f9)] flex flex-row items-center justify-center">
              <div className="relative text-base tracking-[-0.01em] font-medium font-palanquin text-white text-left inline-block min-w-[67px]">
                Subscribe
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-between [row-gap:20px] max-w-full gap-[0px] text-base text-gray-200 mq1325:flex-wrap">
        <div className="w-[1160px] flex flex-row mq450:flex-col mq450:items-start items-center justify-start py-0 pr-[496px] pl-0 box-border gap-[12px] max-w-full mq450:pr-5 mq450:box-border mq800:pr-[124px] mq800:box-border mq1125:flex-wrap mq1325:pr-[248px] mq1325:box-border">
          <div className="flex-1 relative tracking-[-0.01em] font-medium inline-block min-w-[98px]">{`© 2024 PaysSolutions `}</div>
          <div className="relative tracking-[-0.01em] font-medium inline-block min-w-[3px] mq1125:w-full mq1125:h-[3px] mq450:hidden">{`· `}</div>
          <div className="relative tracking-[-0.01em] font-medium inline-block min-w-[123px]">
            All rights reserved
          </div>
          <div className="relative tracking-[-0.01em] font-medium inline-block min-w-[3px] mq1125:w-full mq1125:h-[3px] mq450:hidden">{`· `}</div>
          <div className="flex-1 relative tracking-[-0.01em] font-medium inline-block min-w-[104px]">
            contact@pays.solutions
          </div>
          <div className="relative tracking-[-0.01em] font-medium inline-block min-w-[3px] mq1125:w-full mq1125:h-[3px] mq450:hidden">{`· `}</div>
          <a href="https://app.websitepolicies.com/policies/view/8jc6jd34" className="relative no-underline inline-block whitespace-nowrap text-black visited:text-black"><div className="relative [text-decoration:underline] tracking-[-0.01em] font-medium">{`Legal & Privacy Policy`}</div></a>
        </div>
        <div className="flex flex-row items-start justify-start gap-[12px]">
          <img
            className="h-8 w-8 relative min-h-[32px] cursor-pointer"
            alt=""
            src="/group-1.svg"
            onClick={() => window.location.href='https://x.com/PaysSolutions'}
          />
          <img
            className="h-8 w-8 relative min-h-[32px] cursor-pointer"
            alt=""
            src="/group-2.svg"
            onClick={() => window.location.href='https://www.instagram.com/pays.solutions'}
          />
          <img
            className="h-8 w-8 relative min-h-[32px] cursor-pointer"
            alt=""
            src="/group-3.svg"
            onClick={() => window.location.href='https://t.me/pays_solutions'}
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
