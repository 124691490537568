import { FunctionComponent } from "react";

const TokenSale: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-center justify-center pt-16 px-20 pb-20 mq1200:py-6 mq1200:px-8 mq450:px-4 mq450:py-6 box-border gap-[32px] max-w-full z-[10] text-left text-5xl text-font-color-dark-800 font-palanquin mq800:gap-[16px] mq800:pt-[27px] mq800:px-10 mq800:pb-[34px] mq800:box-border mq1325:pt-[42px] mq1325:pb-[52px] mq1325:box-border">
      <div id="token-sale" className="flex flex-col items-center justify-end gap-[32px] max-w-full text-45xl mq800:gap-[16px]">
        
        <div className="flex flex-col items-center justify-center gap-[24px] mq450:gap-[16px] max-w-full">
          <h1 className="text-inherit tracking-[-0.04em] leading-[100%] font-medium font mq450:text-[38px] my-0 max-w-full">
            Token Allocation
          </h1>
          <div className="flex text-center text-5xl tracking-[-0.03em] leading-[150%] text-darkgray mq450:text-lgi mq450:leading-[29px]">
            "Invest in the Future of Finance"
          </div>
        </div>
        
        <button onClick={() => window.location.href='https://share-eu1.hsforms.com/1T3uP9uTCSK2S_z_EcE4Vqw2dwiun'} className="cursor-pointer [border:none] py-2.5 px-8 bg-[transparent] rounded-29xl [background:linear-gradient(180deg,_#2e79ee,_#30a7f9)] flex flex-row items-center justify-center whitespace-nowrap">
          <div className="relative text-xl tracking-[-0.01em] capitalize font-medium font-palanquin text-white text-left inline-block min-w-[103px]">
            Join waitlist
          </div>
        </button>
      </div>
      <div className="self-stretch hidden flex-col items-center justify-center gap-[32px] max-w-full">
        <div className="self-stretch rounded-3xl bg-whitesmoke-100 flex flex-row items-center justify-between py-8 px-32 box-border max-w-full gap-[20px] mq1325:flex-wrap">
          <div className="h-[34px] w-[448px] relative tracking-[-0.03em] leading-[33.68px] font-medium inline-block shrink-0 max-w-full mq450:text-lgi mq450:leading-[27px]">
            Initial Circulating Supply
          </div>
          <div className="h-[34px] w-[448px] relative tracking-[-0.03em] leading-[33.68px] font-semibold font-poppins text-transparent !bg-clip-text [background:linear-gradient(187.66deg,_#2e79ee,_#30a7f9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] inline-block shrink-0 max-w-full mq450:text-lgi mq450:leading-[27px]">
            $PAYS 26 500 000,00
          </div>
        </div>
        <div className="self-stretch rounded-3xl bg-whitesmoke-100 flex flex-row items-center justify-between py-8 px-32 box-border max-w-full gap-[20px] mq1325:flex-wrap">
          <div className="h-[34px] w-[448px] relative tracking-[-0.03em] leading-[33.68px] font-medium inline-block shrink-0 max-w-full mq450:text-lgi mq450:leading-[27px]">
            Initial Market Cap (excl. Liquidity)
          </div>
          <div className="h-[34px] w-[448px] relative tracking-[-0.03em] leading-[33.68px] font-semibold font-poppins text-transparent !bg-clip-text [background:linear-gradient(187.66deg,_#2e79ee,_#30a7f9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] inline-block shrink-0 max-w-full mq450:text-lgi mq450:leading-[27px]">
            $PAYS 927 500,00
          </div>
        </div>
        <div className="self-stretch rounded-3xl bg-whitesmoke-100 flex flex-row items-center justify-between py-8 px-32 box-border max-w-full gap-[20px] mq1325:flex-wrap">
          <div className="h-[34px] w-[448px] relative tracking-[-0.03em] leading-[33.68px] font-medium inline-block shrink-0 max-w-full mq450:text-lgi mq450:leading-[27px]">{`Fully Diluted Valuation (Listing) `}</div>
          <div className="h-[34px] w-[448px] relative tracking-[-0.03em] leading-[33.68px] font-semibold font-poppins text-transparent !bg-clip-text [background:linear-gradient(187.66deg,_#2e79ee,_#30a7f9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] inline-block shrink-0 max-w-full mq450:text-lgi mq450:leading-[27px]">
            $PAYS 35 000 000,00
          </div>
        </div>
        <div className="self-stretch rounded-3xl bg-whitesmoke-100 flex flex-row items-center justify-between py-8 px-32 box-border max-w-full gap-[20px] mq1325:flex-wrap">
          <div className="h-[34px] w-[448px] relative tracking-[-0.03em] leading-[33.68px] font-medium inline-block max-w-full mq450:text-lgi mq450:leading-[27px]">
            TOTAL TOKENS NUMBER
          </div>
          <div className="h-[34px] w-[448px] relative tracking-[-0.03em] leading-[33.68px] font-semibold font-poppins text-transparent !bg-clip-text [background:linear-gradient(187.66deg,_#2e79ee,_#30a7f9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] inline-block shrink-0 max-w-full mq450:text-lgi mq450:leading-[27px]">
            1 000 000 000
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-center justify-center gap-[48px] max-w-full text-center mq450:gap-[24px]">
        <div className="self-stretch flex flex-col items-center justify-center max-w-full">
          <div className="self-stretch flex flex-col items-center justify-center gap-[32px] max-w-full mq450:gap-[16px]">
            <div className="self-stretch flex flex-row flex-wrap items-center justify-center gap-[32px] max-w-full mq450:gap-[16px]">
              <div className="flex-1 rounded-3xl mq450:rounded-2xl bg-whitesmoke-100 flex flex-col items-center justify-center py-8 px-0 box-border gap-[12px] min-w-[468px] max-w-full mq450:min-w-full">
                <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-medium mq450:text-lgi mq450:leading-[27px]">
                  Initial Circulating Supply
                </div>
                <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-semibold font-poppins text-transparent !bg-clip-text [background:linear-gradient(187.66deg,_#2e79ee,_#30a7f9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-lgi mq450:leading-[27px]">
                  $PAYS 26 500 000,00
                </div>
              </div>
              <div className="flex-1 rounded-3xl mq450:rounded-2xl bg-whitesmoke-100 flex flex-col items-center justify-center py-8 px-0 box-border gap-[12px] min-w-[468px] max-w-full mq450:min-w-full">
                <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-medium mq450:text-lgi mq450:leading-[27px]">
                  Initial Market Cap (excl. Liquidity)
                </div>
                <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-semibold font-poppins text-transparent !bg-clip-text [background:linear-gradient(187.66deg,_#2e79ee,_#30a7f9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-lgi mq450:leading-[27px]">
                  $PAYS 927 500,00
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row flex-wrap items-center justify-center gap-[32px] max-w-full mq450:gap-[16px]">
              <div className="flex-1 rounded-3xl mq450:rounded-2xl bg-whitesmoke-100 flex flex-col items-center justify-center py-8 px-0 box-border gap-[12px] min-w-[468px] max-w-full mq450:min-w-full">
                <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-medium mq450:text-lgi mq450:leading-[27px]">{`Fully Diluted Valuation (Listing) `}</div>
                <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-semibold font-poppins text-transparent !bg-clip-text [background:linear-gradient(187.66deg,_#2e79ee,_#30a7f9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-lgi mq450:leading-[27px]">
                  $PAYS 35 000 000,00
                </div>
              </div>
              <div className="flex-1 rounded-3xl mq450:rounded-2xl bg-whitesmoke-100 flex flex-col items-center justify-center py-8 px-0 box-border gap-[12px] min-w-[468px] max-w-full mq450:min-w-full">
                <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-medium mq450:text-lgi mq450:leading-[27px]">
                  Total Tokens Number
                </div>
                <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-semibold font-poppins text-transparent !bg-clip-text [background:linear-gradient(187.66deg,_#2e79ee,_#30a7f9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-lgi mq450:leading-[27px]">
                1 000 000 000
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="self-stretch relative max-w-full overflow-hidden max-h-full mq800:hidden mq450:hidden"
          alt=""
          src="/wykres_2_2.svg"
        />
        <img
          className="self-stretch relative max-w-full overflow-hidden max-h-full hidden mq450:block mq800:block"
          alt=""
          src="/wykres_2_2_m.svg"
        />
        <div className="w-full hidden mq450:block">
          <div className="flex flex-col gap-[12px]">
        <div className="flex flex-row gap-[12px]">
        <div className="flex flex-col rounded-[12px] w-full bg-whitesmoke-100 items-center justify-center py-[24px] px-[8px] gap-[8px]">
          <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-medium mq450:text-lgi mq450:leading-[27px]">
          Airdrop
            </div>
          <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-semibold font-poppins text-transparent !bg-clip-text [background:linear-gradient(187.66deg,_#2e79ee,_#30a7f9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-lgi mq450:leading-[27px]">
          3.0%
            </div>
        </div>
        <div className="flex flex-col rounded-[12px] w-full bg-whitesmoke-100 items-center justify-center py-[24px] px-[8px] gap-[8px]">
          <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-medium mq450:text-lgi mq450:leading-[27px]">
          Private Round
            </div>
          <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-semibold font-poppins text-transparent !bg-clip-text [background:linear-gradient(187.66deg,_#2e79ee,_#30a7f9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-lgi mq450:leading-[27px]">
          2.5%
            </div>
        </div>
        </div>
        <div className="flex flex-row gap-[12px]">
        <div className="flex flex-col rounded-[12px] w-full bg-whitesmoke-100 items-center justify-center py-[24px] px-[8px] gap-[8px]">
          <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-medium mq450:text-lgi mq450:leading-[27px]">
          Operational
            </div>
          <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-semibold font-poppins text-transparent !bg-clip-text [background:linear-gradient(187.66deg,_#2e79ee,_#30a7f9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-lgi mq450:leading-[27px]">
          15.0%
            </div>
        </div>
        <div className="flex flex-col rounded-[12px] w-full bg-whitesmoke-100 items-center justify-center py-[24px] px-[8px] gap-[8px]">
          <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-medium mq450:text-lgi mq450:leading-[27px]">
          Liquidity
            </div>
          <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-semibold font-poppins text-transparent !bg-clip-text [background:linear-gradient(187.66deg,_#2e79ee,_#30a7f9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-lgi mq450:leading-[27px]">
          8.5%
            </div>
        </div>
        </div>
        <div className="flex flex-row gap-[12px]">
        <div className="flex flex-col rounded-[12px] w-full bg-whitesmoke-100 items-center justify-center py-[24px] px-[8px] gap-[8px]">
          <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-medium mq450:text-lgi mq450:leading-[27px]">
          Public Sale
            </div>
          <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-semibold font-poppins text-transparent !bg-clip-text [background:linear-gradient(187.66deg,_#2e79ee,_#30a7f9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-lgi mq450:leading-[27px]">
          3.0%
            </div>
        </div>
        <div className="flex flex-col rounded-[12px] w-full bg-whitesmoke-100 items-center justify-center py-[24px] px-[8px] gap-[8px]">
          <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-medium mq450:text-lgi mq450:leading-[27px]">
          Team & Advisors
            </div>
          <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-semibold font-poppins text-transparent !bg-clip-text [background:linear-gradient(187.66deg,_#2e79ee,_#30a7f9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-lgi mq450:leading-[27px]">
          15.0%
            </div>
        </div>
        </div>
        <div className="flex flex-row gap-[12px]">
        <div className="flex flex-col rounded-[12px] w-full bg-whitesmoke-100 items-center justify-center py-[24px] px-[8px] gap-[8px]">
          <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-medium mq450:text-lgi mq450:leading-[27px]">
          Ecosystem Fund
            </div>
          <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-semibold font-poppins text-transparent !bg-clip-text [background:linear-gradient(187.66deg,_#2e79ee,_#30a7f9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-lgi mq450:leading-[27px]">
          20.0%
            </div>
        </div>
        <div className="flex flex-col rounded-[12px] w-full bg-whitesmoke-100 items-center justify-center py-[24px] px-[8px] gap-[8px]">
          <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-medium mq450:text-lgi mq450:leading-[27px]">
          Seed Round
            </div>
          <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-semibold font-poppins text-transparent !bg-clip-text [background:linear-gradient(187.66deg,_#2e79ee,_#30a7f9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-lgi mq450:leading-[27px]">
          18.0%
            </div>
        </div>
        </div>
        <div className="flex flex-row gap-[12px]">
        <div className="flex flex-col rounded-[12px] w-full bg-whitesmoke-100 items-center justify-center py-[24px] px-[8px] gap-[8px]">
          <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-medium mq450:text-lgi mq450:leading-[27px]">
          Community Incetives 
            </div>
          <div className="self-stretch relative tracking-[-0.03em] leading-[34px] font-semibold font-poppins text-transparent !bg-clip-text [background:linear-gradient(187.66deg,_#2e79ee,_#30a7f9)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-lgi mq450:leading-[27px]">
          15.0%
            </div>
        </div>
        </div>
        </div>
        </div>
      </div>
    </section>
  );
};

export default TokenSale;
