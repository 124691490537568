import { FunctionComponent } from "react";

const Roadmap1: FunctionComponent = () => {
  return (
    <section className="w-full flex-col items-center">
    <div id="roadmap" className=" flex flex-col items-center justify-start gap-[64px] mq450:gap-[32px] max-w-full z-[0] p-12 mq1200:py-6 mq1200:px-8 mq450:px-4 mq450:py-6 h-full">
        <div className="flex flex-col gap-[24x] items-center">
        <div className="flex flex-col items-center justify-center gap-[24px] mq450:gap-[16px] max-w-full">
          <h1 className="text-inherit tracking-[-0.04em] leading-[100%] font-medium font mq450:text-[38px] my-0 max-w-full">
          Roadmap
          </h1>
          <div className="flex text-center text-5xl tracking-[-0.03em] leading-[150%] text-darkgray mq450:text-lgi mq450:leading-[29px]">
          "Strategic Growth, Step by Step"
          </div>
        </div>
        </div>
        <div className="flex flex-col w-full">
        <div id="PhaseContainterLeft1" className="flex flex-row gap-8 items-start text-5xl w-full">
    <div className="flex flex-col w-full text-right gap-4 pb-[24px] flex-grow mq450:text-[20px]">
        <div className="flex flex-row justify-end">
            <div className="rounded-45xl bg-lavender flex flex-row items-start py-2 px-4 box-border font-medium text-xl mq450:text-[16px] text-darkslateblue">
                <div className="h-6 flex-1 relative leading-[120%] inline-block mq450:h-auto">
                    Phase 1
                </div>
            </div>
        </div>
        <div className="flex flex-col text-right leading-[120%] font-medium w-full break-words mq450:text-[16px]">Market Research</div>
        <div className="flex flex-col text-right leading-[120%] font-medium w-full break-words mq450:text-[16px]">Mobile Wallet Beta</div>
        <div className="flex flex-col text-right leading-[120%] font-medium w-full break-words mq450:text-[16px]">Core Team & Advisors Formation</div>
    </div>
    <div className="flex flex-col flex-grow items-start justify-center py-4 px-0 box-border mq450:hidden">
        <div className="w-[48px] border-t-[4px] border-dashed border-lightgray-200" />
    </div>
    <div className="flex flex-col flex-grow justify-center items-center w-auto">
        <div className="w-8 h-8 rounded-45xl bg-cornflowerblue-100 flex flex-row items-center justify-start p-1 box-border"></div>
        <div className="flex flex-col flex-grow w-1 h-[168px] mq450:h-[200px] justify-center bg-cornflowerblue-100 z-[1]" />
    </div>
    <div className="flex flex-col flex-grow items-start justify-center py-4 px-0 box-border mq450:hidden">
        <div className="w-[48px]" />
    </div>
    <div className="w-full text-left flex-grow"></div>
</div>

        <div id="PhaseContainterRight1" className="w-full h-full flex flex-row gap-8 items-start text-5xl">
        
        <div className="w-full text-left">
        </div>
        
        <div className="flex flex-col text-right h-full gap-4 pb-[24px] mq450:hidden">
        <div className="h-8 flex flex-col items-start justify-center py-4 px-0 box-border mq450:hidden">
              <div className="w-[48px]" />
        </div>
        </div>


        <div className="flex flex-col justify-center h-full">
        <div className="w-8 flex flex-col items-center justify-start h-full">

            <div className="w-8 h-8 rounded-45xl bg-cornflowerblue-100 flex flex-row items-center justify-start p-1 box-border">
            <div className="h-6 w-6 relative rounded-29xl bg-white overflow-hidden shrink-0" />
            </div>

            <div className="w-1 h-[212px] mq450:h-[220px] flex flex-col justify-center bg-cornflowerblue-100 z-[1]" />

        </div>
        </div>




        <div className="h-8 flex flex-col items-start justify-center py-4 px-0 box-border mq450:hidden">
              <div className="w-[48px] border-t-[4px] border-dashed border-lightgray-200" />
        </div>
        




        <div className="flex flex-col w-full text-left h-full gap-4 pb-[24px] mq450:text-[20px]">

        <div className="flex flex-row justify-start w-full">


        <div className="rounded-45xl bg-mediumseagreen-300 flex flex-row items-start py-2 px-4 box-border font-medium text-xl mq450:text-[16px] text-mediumseagreen-100">
            <div className="h-6 flex-1 relative leading-[120%] inline-block mq450:h-auto">
                  Phase 2
            </div>
        </div>
        </div>
        
        <div className="flex flex-col text-left leading-[120%] font-medium mq450:text-[16px]">Mobile Wallet v1</div>
        <div className="flex flex-col text-left leading-[120%] font-medium mq450:text-[16px]">Pays Payroll Beta</div>
        <div className="flex flex-col text-left leading-[120%] font-medium mq450:text-[16px]">Seed Round</div>
        <div className="flex flex-col text-left leading-[120%] font-medium mq450:text-[16px]">Gasless Implementation</div>
        </div>
    
        
        
        </div>
        <div id="PhaseContainterLeft2" className="w-full h-full flex flex-row gap-8 items-start text-5xl">
        
        <div className="flex flex-col w-full text-right h-full gap-4 pb-[24px] mq450:text-[20px]">


        <div className="flex flex-row justify-end">
        <div className="rounded-45xl bg-tomato-300 flex flex-row items-start py-2 px-4 box-border font-medium text-xl mq450:text-[16px] text-tomato-100">
            <div className="h-6 flex-1 relative leading-[120%] inline-block mq450:h-auto">
                  Phase 3
            </div>
        </div>
        </div>
        
        <div className="flex flex-col text-right leading-[120%] font-medium mq450:text-[16px]">Pays Payroll v1</div>
        <div className="flex flex-col text-right leading-[120%] font-medium mq450:text-[16px]">Clients Onboarding PoC</div>
        <div className="flex flex-col text-right leading-[120%] font-medium mq450:text-[16px]">Private Round</div>
        </div>

            
        <div className="h-8 flex flex-col items-start justify-center py-4 px-0 box-border mq450:hidden">
              <div className="w-[48px] border-t-[4px] border-dashed border-lightgray-200" />
        </div>

        <div className="flex flex-col justify-center h-full">
        <div className="w-8 flex flex-col items-center justify-start h-full">

            <div className="w-8 h-8 rounded-45xl bg-cornflowerblue-100 flex flex-row items-center justify-start p-1 box-border">
            <div className="h-6 w-6 relative rounded-29xl bg-white overflow-hidden shrink-0" />
            </div>

            <div className="w-1 h-[168px] mq450:h-[176px] flex flex-col justify-center bg-cornflowerblue-100 z-[1]" />

        </div>
        </div>

        <div className="h-8 flex flex-col items-start justify-center py-4 px-0 box-border mq450:hidden">
              <div className="w-[48px]"/>
        </div>
        
        <div className="w-full text-left">
        </div>
        
        
        </div>

        <div id="PhaseContainterRight2" className="w-full h-full flex flex-row gap-8 items-start text-5xl">
        
        <div className="w-full text-left">
        </div>
        
        <div className="h-8 flex flex-col items-start justify-center py-4 px-0 box-border mq450:hidden">
              <div className="w-[48px]"/>
        </div>

        <div className="flex flex-col justify-center h-full">
        <div className="w-8 flex flex-col items-center justify-start h-full">

            <div className="w-8 h-8 rounded-45xl bg-cornflowerblue-100 flex flex-row items-center justify-start p-1 box-border">
            <div className="h-6 w-6 relative rounded-29xl bg-white overflow-hidden shrink-0" />
            </div>

            <div className="w-1 h-[124px] mq450:h-[160px] flex flex-col justify-center bg-cornflowerblue-100 z-[1]" />

        </div>
        </div>




        <div className="h-8 flex flex-col items-start justify-center py-4 px-0 box-border mq450:hidden">
              <div className="w-[48px] border-t-[4px] border-dashed border-lightgray-200" />
        </div>
        




        <div className="flex flex-col w-full text-right h-full gap-4 pb-[24px] mq450:text-[20px]">

        <div className="flex flex-row justify-start w-full">

        <div className="rounded-45xl bg-purple-200 flex flex-row items-start py-2 px-4 box-border font-medium text-xl mq450:text-[16px] text-purple-700">
            <div className="h-6 flex-1 relative leading-[120%] inline-block mq450:h-auto">
                  Phase 4
            </div>
        </div>
        </div>
        
        <div className="flex flex-col text-left leading-[120%] font-medium w-full mq450:text-[16px]">Community Building</div>
        <div className="flex flex-col text-left leading-[120%] font-medium w-full mq450:text-[16px]">PR/Media Campaign</div>
        </div>
    
        
        
        </div>
        <div id="PhaseContainterLeft3" className="w-full h-full flex flex-row gap-8 items-start text-5xl">
        
        <div className="flex flex-col w-full text-right h-full gap-4 pb-[24px] mq450:text-[20px]">

        <div className="flex flex-row justify-end">
        
        <div className="rounded-45xl bg-fuchsia-300 flex flex-row items-start py-2 px-4 box-border font-medium text-xl mq450:text-[16px] text-fuchsia-100">
            <div className="h-6 flex-1 relative leading-[120%] inline-block mq450:h-auto">
                  Phase 5
            </div>
        </div>
        </div>
        
        <div className="flex flex-col text-right leading-[120%] font-medium mq450:text-[16px]">Security Audits</div>
        <div className="flex flex-col text-right leading-[120%] font-medium mq450:text-[16px]">TGE Campaign</div>
        <div className="flex flex-col text-right leading-[120%] font-medium mq450:text-[16px]">TGE Event</div>
        </div>

            
        <div className="h-8 flex flex-col items-start justify-center py-4 px-0 box-border mq450:hidden">
              <div className="w-[48px] border-t-[4px] border-dashed border-lightgray-200" />
        </div>

        <div className="flex flex-col justify-center h-full">
        <div className="w-8 flex flex-col items-center justify-start h-full">

            <div className="w-8 h-8 rounded-45xl bg-cornflowerblue-100 flex flex-row items-center justify-start p-1 box-border">
            <div className="h-6 w-6 relative rounded-29xl bg-white overflow-hidden shrink-0" />
            </div>

            <div className="w-1 h-[168px] flex flex-col justify-center bg-cornflowerblue-100 z-[1]" />

        </div>
        </div>

        <div className="h-8 flex flex-col items-start justify-center py-4 px-0 box-border mq450:hidden">
              <div className="w-[48px]"/>
        </div>
        
        <div className="w-full text-left">
        </div>
        
        
        </div>

        <div id="PhaseContainterRight3" className="w-full h-full flex flex-row gap-8 items-start text-5xl">
        
        <div className="w-full text-left">
        </div>
        
        <div className="flex flex-col text-right h-full gap-4 pb-[24px] break-all mq450:hidden">

        
        <div className="h-8 flex flex-col items-start justify-center py-4 px-0 box-border">
              <div className="w-[48px]"/>
        </div>
        </div>


        <div className="flex flex-col justify-center h-full">
        <div className="w-8 flex flex-col items-center justify-start h-full">

            <div className="w-8 h-8 rounded-45xl bg-cornflowerblue-100 flex flex-row items-center justify-start p-1 box-border">
            <div className="h-6 w-6 relative rounded-29xl bg-white overflow-hidden shrink-0" />
            </div>

            <div className="w-1 flex flex-col justify-center bg-cornflowerblue-100 z-[1]" />
            
        </div>
        </div>




        <div className="h-8 flex flex-col items-start justify-center py-4 px-0 box-border mq450:hidden">
              <div className="w-[48px] border-t-[4px] border-dashed border-lightgray-200 " />
        </div>
        




        <div className="flex flex-col w-full text-right h-full gap-4 pb-[24px] mq450:text-[20px]">

        <div className="flex flex-row justify-start w-full">
        
        <div className="rounded-45xl  bg-blanchedalmond flex flex-row items-start py-2 px-4 box-border font-medium text-xl mq450:text-[16px] text-gray-100">
            <div className="h-6 flex-1 relative leading-[120%] inline-block mq450:h-auto">
                  Phase 6
            </div>
        </div>
        </div>
        
        <div className="flex flex-col text-left leading-[120%] font-medium mq450:text-[16px]">Pays Payroll v2</div>
        <div className="flex flex-col text-left leading-[120%] font-medium mq450:text-[16px]">Pays Work v1</div>
        <div className="flex flex-col text-left leading-[120%] font-medium mq450:text-[16px]">Pays Trips v1</div>
        </div>
    
        
        
        </div>
        </div>


        
    </div>
    </section>
  );
};

export default Roadmap1;
