import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

interface BurgerIconProps {
  isMenuVisible: boolean;
  toggleMenu: () => void;
}

const BurgerIcon: FunctionComponent<BurgerIconProps> = ({ isMenuVisible, toggleMenu }) => {
  return (
    <button onClick={toggleMenu} className="relative group bg-transparent z-32 cursor-pointer hidden mq450:block">
      <div className="relative flex overflow-hidden items-center justify-center w-[50px] h-[50px] transform transition-all duration-200">
        <div className="flex flex-col justify-between w-[20px] h-[20px] transform transition-all duration-300 origin-center overflow-hidden">
          <div
            className={classNames(
              'bg-black h-[2px] w-7 transform transition-all duration-300 origin-left',
              { 'rotate-[42deg]': isMenuVisible }
            )}
          ></div>
          <div
            className={classNames(
              'bg-black h-[2px] w-7 rounded transform transition-all duration-300',
              { 'opacity-0 invisible': isMenuVisible }
            )}
          ></div>
          <div
            className={classNames(
              'bg-black h-[2px] w-7 transform transition-all duration-300 origin-left',
              { '-rotate-[42deg]': isMenuVisible }
            )}
          ></div>
        </div>
      </div>
    </button>
  );
};

export default BurgerIcon;
