import { FunctionComponent } from "react";

const Hero: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-row mq450:flex-col items-center justify-between py-8 px-20 mq1200:py-6 mq1200:px-8 mq450:px-4 mq450:py-6  box-border gap-[-277px] max-w-full text-left text-77xl mq450:text-[48px] text-gray-300 font-palanquin mq450:pt-5 mq450:pb-5 mq450:box-border mq1125:pt-[21px] mq1125:pb-[21px] mq1125:box-border mq1325:pl-10 mq1325:pr-10 mq1325:box-border mq450:gap-[24px]">
      <div className="w-[522px] flex flex-col items-start justify-start gap-[48px] max-w-full mq450:gap-[24px]">
        <div className="self-stretch flex flex-col items-start justify-start gap-[32px] mq450:gap-[24px] mq800:gap-[16px]">
          <h1 className="m-0 self-stretch relative text-inherit tracking-[-0.04em] leading-[100%] font-inherit mq450:text-10xl mq450:leading-[38px] mq800:text-29xl mq800:leading-[58px]">
            <p className="m-0">
              <span className="font-palanquin text-gray-300 mq450:text-[48px]">{`Pay `}</span>
              <span className="text-dodgerblue-100">
                <span className="font-medium mq450:text-[48px] font-palanquin">anyone</span>
                <span className="font-palanquin">,</span>
              </span>
              <span className="font-palanquin">
                <span className="text-gray-300">{` `}</span>
              </span>
              <span className="text-dodgerblue-100">
                <span className="font-medium font-palanquin mq450:text-[48px]">anywhere</span>
                <span className="font-palanquin">,</span>
              </span>
              <span className="font-palanquin">
                <span className="text-gray-300">{` `}</span>
              </span>
              <span className="text-dodgerblue-100">
                <span className="font-medium font-palanquin mq450:text-[48px]">anytime</span>
              </span>
              <span>
                <span className="text-dodgerblue-100">,</span>
                <span>{` `}</span>
              </span>
            </p>
            <p className="m-0 mq450:text-[48px]">
              <span>
                <span>in seconds</span>
              </span>
            </p>
          </h1>
          <div className="self-stretch relative text-5xl tracking-[-0.03em] leading-[150%] text-darkgray mq450:text-[20px] mq450:leading-[29px]">
            Pays Solutions is dedicated to revolutionizing payments in the Web3
            ecosystem, providing seamless and compliant hybrid payment solutions
            that empower organizations and individuals globally.
          </div>
        </div>
        <div className="w-full flex flex-row flex-wrap items-start justify-start gap-[12px] mq450:gap-[8px] max-w-full">
          <button onClick={() => window.location.href='https://share-eu1.hsforms.com/1T3uP9uTCSK2S_z_EcE4Vqw2dwiun'} className="cursor-pointer border-[2px] border-solid border-white py-2.5 px-8 mq450:text-[14px] mq450:py-[12px] mq450:px-[24px] bg-[transparent] rounded-29xl [background:linear-gradient(180deg,_#2e79ee,_#30a7f9)] flex flex-row items-center justify-center whitespace-nowrap">
            <div className="relative text-xl tracking-[-0.01em] capitalize font-medium mq450:text-[16px] font-palanquin text-white text-left inline-block">
              Join waitlist
            </div>
          </button>
          <button onClick={() => window.location.href='/Pays Solution - White Paper5.pdf'} className="cursor-pointer py-2.5 px-8 bg-[transparent] mq450:text-[16px] mq450:py-[12px] mq450:px-[24px] rounded-29xl box-border flex flex-row items-center justify-center border-[2px] border-solid border-whitesmoke-200 hover:bg-lightgray-300 hover:box-border hover:border-[2px] hover:border-solid hover:border-lightgray-100">
            <div className="text-xl tracking-[-0.01em] font-medium mq450:text-[16px] font-palanquin text-gray-200 text-left inline-block m-0">
              Whitepaper
            </div>
          </button>
        </div>
      </div>
      <img
        className="w-[594px] relative max-h-full hidden max-w-full z-[2]"
        alt=""
        src="/frame.svg"
      />
      <img
        className="mq450:order-first w-[718px] relative max-h-full overflow-hidden shrink-0 object-contain max-w-full"
        loading="lazy"
        alt=""
        src="/hero-image.svg"
      />
    </section>
  );
};

export default Hero;
