import { FunctionComponent } from "react";

const AboutUs: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-center justify-center py-32 px-20 mq1200:py-6 mq1200:px-8 mq450:px-4 mq450:py-6 box-border max-w-full text-left text-45xl text-font-color-dark-800 font-palanquin mq450:box-border mq800:gap-[24px] mq800:py-[83px] mq800:px-10 mq800:box-border">
      <div className="self-stretch flex flex-row mq450:flex-col items-center justify-start gap-[64px] max-w-full mq450:gap-[16px] mq800:gap-[32px] mq1325:flex-wrap">
        <div className="w-[512px] flex flex-row items-center justify-start py-0 pr-px pl-0 box-border min-w-[512px] max-w-full mq450:min-w-full">
          <img
            className="h-auto flex-1 relative max-w-full overflow-hidden"
            loading="lazy"
            alt=""
            src="/about-us-image.svg"
          />
        </div>
        <div className="flex-1 flex flex-col items-start justify-start py-5 px-0 box-border gap-[32px] max-w-full mq450:gap-[24px]">
          
          <div id="about-us" className="self-stretch flex flex-col items-start justify-center gap-[24px] mq450:gap-[16px] mq450:items-center">
            <h1 className="m-0 relative text-inherit tracking-[-0.04em] leading-[100%] font-medium font-inherit mq450:text-19xl mq450:leading-[38px] mq800:text-32xl mq800:leading-[51px]">
              About Us
            </h1>
            <div className="mq450:items-center mq450:text-center self-stretch text-5xl tracking-[-0.03em] leading-[150%] text-darkgray mq450:text-lgi mq450:leading-[29px]">
              "Revolutionizing Global Remittance Market Through Blockchain"
            </div>
          </div>

          <div className="self-stretch flex flex-col items-start justify-start gap-[24px] text-xl text-darkgray">
            <div className="self-stretch relative tracking-[-0.03em] leading-[150%] mq450:text-base mq450:leading-[24px]">
              <span>{`Pays Solutions is a pioneering `}</span>
              <span className="font-medium text-font-color-dark-800">
                WEB3 ERC-4337 wallet
              </span>
              <span>{` enabling account abstraction, gasless transaction, batch transfers, allowing web2 users to adopt into web3 with seamless experience. `}</span>
            </div>
            <div className="self-stretch relative tracking-[-0.03em] leading-[150%] mq450:text-base mq450:leading-[24px]">
              <span>{`We bridge the gap between traditional payment systems and the decentralized world of cryptocurrencies. By leveraging `}</span>
              <span className="font-medium text-font-color-dark-800">
                blockchain technology,
              </span>
              <span>{` smart contracts, and automated processes, Pays Solutions simplifies global money transfers , enhances speed and security, promotes financial inclusion for `}</span>
              <span className="font-medium text-font-color-dark-800">
                unbanked populations.
              </span>
            </div>
          </div>
          <div onClick={() => window.location.href='/Pays Solution - White Paper5.pdf'} className="cursor-pointer self-stretch flex flex-row items-center justify-start py-0 pl-0 gap-[16px] mq450:gap-[12px] text-5xl">
            <div className="relative [text-decoration:underline] leading-[34px] inline-block mq450:text-[20px] mq450:leading-[27px]">
              Whitepaper
            </div>
            <img
              className="h-8 w-8 relative rounded-81xl overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/icon.svg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
