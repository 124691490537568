import { FunctionComponent } from "react";

export type CardsType = {
  iconTablerIconsHierarchy?: string;
  massOnChainPayments?: string;
  streamlineBulkTransaction?: string;
};

const Cards: FunctionComponent<CardsType> = ({
  iconTablerIconsHierarchy,
  massOnChainPayments,
  streamlineBulkTransaction,
}) => {
  return (
    <div className="flex-1 rounded-13xl bg-whitesmoke-100 overflow-hidden flex flex-col items-start justify-start pt-12 px-12 pb-[81px] box-border gap-[48px] min-w-[222px] w-full text-left text-5xl text-font-color-dark-800 font-palanquin mq450:rounded-[12px] mq450:gap-[16px] mq450:p-[24px] mq450:box-border">
      <button className="cursor-pointer [border:none] p-4 bg-cornflowerblue-100 w-16 h-16 rounded-[46.48px] flex flex-row items-center justify-center box-border">
        <img
          className="h-8 w-8 relative overflow-hidden shrink-0"
          alt=""
          src={iconTablerIconsHierarchy}
        />
      </button>
      <div className="self-stretch flex flex-col items-center justify-start gap-[20px] mq450:gap-[8px]">
        <div className="self-stretch relative tracking-[-0.01em] leading-[37.18px] capitalize font-medium mq450:text-lgi mq450:leading-[30px]">
          {massOnChainPayments}
        </div>
        <div className="self-stretch relative tracking-[-0.03em] leading-[33.68px] text-darkgray mq450:text-lgi mq450:leading-[27px]">
          {streamlineBulkTransaction}
        </div>
      </div>
    </div>
  );
};

export default Cards;
